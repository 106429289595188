import { useEffect, useRef, SetStateAction, Dispatch } from 'react'
import { FaDirections } from 'react-icons/fa';
import { RiPhoneFill } from 'react-icons/ri'
import { Link } from 'react-scroll'
import { Turn as Hamburger } from 'hamburger-react'
import './Navbar.css'

interface NavbarProps {
  showNavbar: boolean;
  setShowNavbar: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const Navbar = ({ showNavbar, setShowNavbar, isOpen, setOpen }: NavbarProps) => {
  const navbarRef = useRef<HTMLDivElement>(null)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
    setOpen(!isOpen)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target as Node)) {
      setShowNavbar(false)
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div ref={navbarRef}>
      <nav className="navbar">
        <div className="container">
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <div className="menu-icon" onClick={handleShowNavbar}>
              <Hamburger size={20} direction="right" toggled={isOpen} toggle={setOpen} color="#4F6133"/>
            </div>
            <button className="navbar-text" onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                setShowNavbar(false)
                setOpen(false)
              }}>
              <span style={{color:"#4F6133"}}>Down Town</span>
            </button>
          </div>
          <div className="icons-container">
            <a className="navbar-icon" href="tel:08-550 666 76">
              <RiPhoneFill color="#4F6133" style={{marginRight: "7px"}} />
              <span style={{color: "#4F6133"}}>Ring nu</span>
            </a>
            <a className="navbar-icon" href="https://www.google.com/maps/search/?api=1&query=DownTown Steakhouse & Meze, Nygatan, Södertälje" target="_blank"> 
              <FaDirections color="#4F6133" style={{marginRight: "7px"}} />
              <span style={{color: "#4F6133"}}>Visa vägen</span>
            </a>
          </div>
        </div>
      </nav>
    <div className={`nav-elements  ${showNavbar && 'active'}`}>
      <ul>
        <li>
          <Link onClick={handleShowNavbar}  to="gallery-section" smooth={true} duration={500}><span style={{color:"#4F6133", fontWeight: "500"}}>GALLERI</span></Link>
        </li>
        <li>
          <Link onClick={handleShowNavbar}  to="lunch-section" smooth={true} duration={500}><span style={{color:"#4F6133", fontWeight: "500"}}>LUNCH</span></Link>
        </li>
        <li>
          <Link onClick={handleShowNavbar}  to="price-section" smooth={true} duration={500}><span style={{color:"#4F6133", fontWeight: "500"}}>MENY</span></Link>
        </li>
        <li>
          <Link onClick={handleShowNavbar}  to="info-section" smooth={true} duration={500}><span style={{color:"#4F6133", fontWeight: "500"}}>KONTAKT</span></Link>
        </li>
      </ul>
    </div>
  </div>
  )
}
export default Navbar