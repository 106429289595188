import './BottomMenu.css'
import { FaDirections } from 'react-icons/fa';
import { RiPhoneFill } from 'react-icons/ri'


const BottomMenu = () => {
  return (
    <nav className="bottom-menu">
        <a className="bottom-menu-icon" href="tel:08-550 666 76">
            <RiPhoneFill style={{marginRight: "7px"}} color="#4F6133"/>
            <span style={{color: "#4F6133"}}>Ring nu</span>
        </a>
        <a className="bottom-menu-icon" href="https://www.google.com/maps/search/?api=1&query=DownTown Steakhouse & Meze, Nygatan, Södertälje" target="_blank"> 
            <FaDirections style={{marginRight: "7px"}} color="#4F6133"/>
            <span style={{color: "#4F6133"}}>Visa vägen</span>
        </a>
    </nav>
  )
}

export default BottomMenu;