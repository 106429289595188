import './Home.css'

const Home = () => {

  type OpeningHours = {
    [key: string]: {
      open: string;
      close: string;
    };
  };

  const openingHours: OpeningHours = {
    Sunday: { open: "13:00", close: "22:00" },
    Monday: { open: "11:00", close: "22:00" },
    Tuesday: { open: "11:00", close: "22:00" },
    Wednesday: { open: "11:00", close: "22:00" },
    Thursday: { open: "11:00", close: "22:00" },
    Friday: { open: "11:00", close: "22:00" },
    Saturday: { open: "13:00", close: "23:00" },
  };

  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const today = new Date();
  const dayOfWeek = daysOfWeek[today.getDay()];
  const currentTime = `${today.getHours()}:${("0" + today.getMinutes()).slice(-2)}`;
  const currentTimeHour = parseInt(currentTime.split(":")[0]);

  const currentTimeMinute = parseInt(currentTime.split(":")[1]);

  const openingTime = openingHours[dayOfWeek].open;
  const openingTimeHour = parseInt(openingTime.split(":")[0]);
  const openingTimeMinute = parseInt(openingTime.split(":")[1]);

  const closingTime = openingHours[dayOfWeek].close;
  const closingTimeHour = parseInt(closingTime.split(":")[0]);
  const closingTimeMinute = parseInt(closingTime.split(":")[1]);

  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const tomorrowDayOfWeek = daysOfWeek[tomorrow.getDay()];
  const tomorrowOpeningTime = openingHours[tomorrowDayOfWeek].open;
  const tomorrowOpeningTimeHour = parseInt(tomorrowOpeningTime.split(":")[0]);
  const tomorrowOpeningTimeMinute = parseInt(tomorrowOpeningTime.split(":")[1]);

  let status;
  if (
    (currentTimeHour < openingTimeHour) || (currentTimeHour === openingTimeHour && currentTimeMinute < openingTimeMinute)
  ) 
  {
    status = `Stängt, öppnar ${openingTime} idag`;
  } 
  else if (
    currentTimeHour > closingTimeHour ||
    (currentTimeHour === closingTimeHour && currentTimeMinute > closingTimeMinute)
  ) 
  {
      status = `Stängt, öppnar ${tomorrowOpeningTime} imorgon`;
  } 
  else {
    status = `Öppet till ${closingTime} idag`;
  }

  return (
    <div className="home-container">
      <h2 className='home-heading'>
        <span style={{color: "#4F6133"}}>
          Down Town
        </span>
      </h2>
      <span className='home-text1' style={{fontSize: "1.3rem"}}>
        Steakhouse & Meze Restaurang
      </span>
      <span className='home-text1' style={{fontSize: "1.3rem"}}>{status}</span>
      <a href="tel:08-711 92 08" className='book-button'>
        Boka bord
      </a>
      <div className="BiTRX">
        <picture style={{textAlign: "center",}}>
          <img src={require('../../assets/images/homeimg.webp')} className="n9wygc"/>
        </picture>
      </div>
    </div>
  )
}


export default Home;