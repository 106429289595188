import React from 'react';

const OpeningHours = () => {
  const days = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'];
  const times = ['1.11:00 - 22:00', '2.11:00 - 22:00', '3.11:00 - 22:00', '4.11:00 - 22:00', '5.11:00 - 00:00', '6.13:00 - 00:00', '7.13:00 - 22:00'];

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {days.map((day) => (
          <div key={day} style={{ paddingLeft: '10px' }}>
            {day}
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {times.map((time) => (
          <div key={time}>
            <span style={{ paddingLeft: '10px' }}>{time.charAt(0).split('.')[1]}</span>
            <span>{time.substring(1).split('.')[1]}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OpeningHours;