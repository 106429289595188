import React, { useState } from "react";
import Navbar from "./components/navbar/Navbar";
import Home from "./components/Home/Home";
import Info from "./components/Info/Info";
import BottomMenu from "./components/bottomMenu/BottomMenu";
import Gallery from "./components/Gallery/Gallery";
import PriceList from "./components/PriceList/PriceList";
import Lunch from "./components/lunchInfo/lunchInfo";

const App = () => {
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const [showNavbar, setShowNavbar] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [canSwipe, setCanSwipe] = useState(true);
  const minSwipeDistance = 70;

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    console.log("swipe", isLeftSwipe ? "left" : "right");

    console.log("touchStart: " + touchStart);

    if (isLeftSwipe && canSwipe) {
      setShowNavbar(!isLeftSwipe);
      setOpen(!isLeftSwipe);
    } else if (isRightSwipe && touchStart < 50 && canSwipe) {
      setShowNavbar(!isLeftSwipe);
      setOpen(!isLeftSwipe);
    }
  };

  return (
    <div
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <Navbar
        showNavbar={showNavbar}
        setShowNavbar={setShowNavbar}
        isOpen={isOpen}
        setOpen={setOpen}
      />
      <section style={{ marginTop: "60px" }} id="top-section">
        <Home />
      </section>
      <section style={{ marginTop: "60px" }} id="gallery-section">
        <Gallery
          changeCanSwipe={setCanSwipe}
          hideTitle={false}
          showFirstThree={true}
        />
      </section>
      <section style={{ marginTop: "60px" }} id="lunch-section">
        <Lunch />
        <Gallery
          changeCanSwipe={setCanSwipe}
          hideTitle={true}
          showFirstThree={false}
        />
      </section>
      <section style={{ marginTop: "60px" }} id="price-section">
        <PriceList />
      </section>
      <section style={{ marginTop: "60px" }} id="info-section">
        <Info />
      </section>
      <BottomMenu />
    </div>
  );
};

export default App;
