import "./Info.css";
import OpeningHours from "./OpeningHours";

const Info = () => {
  return (
    <div className="info-container">
      <div className="title-container">
        <div className="title-stripe title-stripe-left"></div>
        <div className="title-text">Kontakta oss</div>
        <div className="title-stripe title-stripe-right"></div>
      </div>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2043.1874231405009!2d17.6200420368044!3d59.19616436992622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f129e62609f33%3A0x80e4864d1a24a524!2sDownTown%20Steakhouse%20%26%20Meze!5e0!3m2!1ssv!2sse!4v1676231885604!5m2!1ssv!2sse"
        className="iframe-info"
      ></iframe>

      <div className="info-details-container">
        <div className="info-details">
          <div className="info-details-header">Kontakt</div>
          <div className="info-details-content">
            <a href="tel:08-550 666 76" className="info-button">
              Ring nu
            </a>
            <pre style={{ fontFamily: 'inherit' }}>
              Tel:    08-550 666 76<br></br>
              Mob: 073-968 92 59
            </pre>
            <div className="socials-logo-container">
              <img
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/RestaurangDownTown",
                    "_blank"
                  )
                }
                src={require("../../assets/icons/fb_logo_black.png")}
                className="socials-logo"
              />
              <img
                src={require("../../assets/icons/ig_logo_black.png")}
                className="socials-logo"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/downtownsodertalje/",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="info-details">
          <div className="info-details-header">Adress</div>
          <div className="info-details-content">
            <a
              href="https://www.google.com/maps/search/?api=1&query=DownTown Steakhouse & Meze, Nygatan, Södertälje"
              target="_blank"
              className="info-button"
            >
              Visa vägbeskrivning
            </a>
            <p>Nygatan 19, 151 73 Södertälje</p>
          </div>
        </div>
        <div className="info-details">
          <div className="info-details-header">Öppettider</div>
          <div className="info-details-content">
            <OpeningHours />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
