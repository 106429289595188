import "./PriceList.css";
import MenuSection from "./MenuSection";

const PriceList = () => {
  interface PriceItem {
    name: string;
    price: number;
  }

  const starters = [
    { name: "Vitlöksbröd med tzatziki", price: "49kr", description: "" },
    { name: "Mozzarella Caprese", price: "109kr", description: "" },
    { name: "Klassisk Toast Skagen", price: "119kr", description: "" },
    { name: "Scampi", price: "119kr", description: "" },
  ];

  const sallads = [
    { name: "Haloumi sallad ", price: "179kr", description: "" },
    { name: "Caesarsallad", price: "179kr", description: "" },
    { name: "Caesarsallad med Scampi", price: "189kr", description: "" },
  ];

  const pasta = [
    {
      name: "Kycklingpasta á la Down Town",
      price: "189kr",
      description: "Vitlök, paprika, zucchini, grädde & lök",
    },
    {
      name: "Pasta Indiana",
      price: "199kr",
      description: "Scampi, champinjoner, lök, paprika, curry & grädde",
    },
    {
      name: "Oxfilépasta",
      price: "209kr",
      description:
        "Vitlök, grädde, sambal, färska champinjoner, lök & parmesan",
    },
  ];

  const steakhouse = [
    {
      name: "Chicken Breast Mozzarella",
      price: "229kr",
      description: "Grillad kycklingfilé toppad med mozzarella & Barbecuesås",
    },
    {
      name: "Grilled Salmon",
      price: "239kr",
      description: "grillad lax, pesto",
    },
    { name: "Lammracks", price: "289kr", description: "grillade grönsaker" },
    {
      name: "Striploin Steak 220g",
      price: "289kr",
      description: "Grillad australiensisk ryggbiff, grillade grönsaker",
    },
    {
      name: "Ribeye Steak 220g",
      price: "289kr",
      description: "Grillad entrecote, grillade grönsaker",
    },
    {
      name: "Tenderloin ”Million dollar” -steak",
      price: "319kr",
      description: "Grillad oxfile, grillade grönsaker",
    },
  ];

  const plankstek = [
    { name: "Lax på planka", price: "249kr", description: "" },
    { name: "Biff på planka", price: "289kr", description: "" },
    { name: "Oxfile på planka", price: "319kr", description: "" },
  ];

  const kall_meze = [
    { name: "Hummus", price: "60kr", description: "krämig kikärtsröra" },
    {
      name: "Baba Ghanoush",
      price: "60kr",
      description: "Krämig, kryddad aubergineröra",
    },
    {
      name: "Labneh",
      price: "60kr",
      description: "orientalisk kryddad mezeyoghurt",
    },
    {
      name: "Mhamara",
      price: "60kr",
      description: "Stark parikaröra med valnötter",
    },
    {
      name: "Taratour Djej ",
      price: "70kr",
      description: "Kycklingröra med saltgurka & sesampasta",
    },
    {
      name: "Kronärtskocka",
      price: "70kr",
      description: "Marinerad i olivolja & citron, vitlök & pepparmix",
    },
    {
      name: "Warak Enab",
      price: "70kr",
      description: "Vegetariska vinbladsolmar",
    },
    {
      name: "Fattoush",
      price: "90kr",
      description: "Kryddig sallad med rostat libanesiskt bröd",
    },
    {
      name: "Tabbouleh ",
      price: "90kr",
      description: "Persiljesallad med tomat, lök, bulgur & citron",
    },
    { name: "Basterma", price: "119kr", description: "Torkat, kryddat oxkött" },
    {
      name: "Kebbe Neije",
      price: "149kr",
      description: "råbiff från mellanöstern",
    },
  ];

  const varm_meze = [
    {
      name: "Rakakat Jebneh",
      price: "65kr",
      description: "Ostfyllda filodegsrullar",
    },
    { name: "Fatayer Sabanegh", price: "65kr", description: "Spenatpiroger" },
    { name: "Sambosek", price: "65kr", description: "Nötfärspiroger" },
    {
      name: "Jawaneh",
      price: "65kr",
      description: "Kycklingvingar marinerade i vitlök",
    },
    {
      name: "Kebbe Trablosie",
      price: "70kr",
      description: "Friterade nötfärsfyllda bulgurbollar",
    },
    { name: "Friterad Halloumi", price: "70kr", description: "" },
    {
      name: "Scampi",
      price: "110kr",
      description: "Med vitlök, citron & olivolja.",
    },
  ];

  const grillspett = [
    { name: "Grönsaksspett", price: "50kr", description: "" },
    { name: "Kycklingspett", price: "60kr", description: "" },
    { name: "Nötfärsspett", price: "70kr", description: "" },
    { name: "Oxfiléspett", price: "110kr", description: "" },
  ];

  const meze_menyer = [
    {
      name: "Meny 1 (minst 2 personer)",
      price: "299kr/ person",
      description:
        "13 kalla & varma meze: Hummus, mhamara, labneh, baba ghanoush, tarator djej, tabbouleh, basterma, rakakat, jebneh, fatayer, sabanegh, friterad halloumi, jawaneh, sambosek & kebbe trablosie.",
    },
    {
      name: "Meny 2 (minst 2 personer)",
      price: "319kr/ person",
      description:
        "Grillmix av 2st. nötfärsspett, 2st kycklingspett samt 8st. kalla & varma meze: Hummus, mhamara, labneh, baba ghanoush tabbouleh, warak enab, rakakat jebneh, fatayer sabanegh & pommes frites.",
    },
    {
      name: "Meny 3 (minst 2 personer)",
      price: "389kr/ person",
      description:
        "Grillmix 2st. kycklingspett, 2st. oxfiléspett, 2st grönsaksspett samt 9st. Kalla, varma meze & baklava: Hummus, mhamara, baba ghanoush, tarator djej, kronärtskocka, tabbouleh, vinbladsdolmar, rakakat jebneh, kebbe trablosie & pommes frites. Kaffe/te ingår i menyn.",
    },
  ];

  const barnnmeny = [
    {
      name: "Tom & Jerry",
      price: "119kr",
      description: "1st. kycklingspett, pommes frites & tzatziki",
    },
    {
      name: "Kalle Anka",
      price: "119kr",
      description: "Grillad hamburgare (90g), pommes frites",
    },
    {
      name: "Nalle Puh",
      price: "119kr",
      description: "6st. Chicken Nuggets, pommes frites",
    },
  ];

  const desserts = [
    {
      name: "Vaniljglass",
      price: "60kr",
      description: "Serveras med chokladsås & grädde",
    },
    { name: "Kladdkaka", price: "75kr", description: "Serveras med grädde" },
    { name: "Baklawa", price: "75kr", description: "" },
    { name: "Kokosglass", price: "95kr", description: "Serveras med grädde" },
  ];

  return (
    <div className="price-container">
      <div className="title-container">
        <div className="title-stripe title-stripe-left"></div>
        <div className="title-text">Vår meny</div>
        <div className="title-stripe title-stripe-right"></div>
      </div>
      <MenuSection sectionName="Förätter" items={starters} />
      <MenuSection sectionName="Sallad" items={sallads} />
      <MenuSection sectionName="Pasta" items={pasta} />
      <MenuSection sectionName="Steakhouse" items={steakhouse} />
      <MenuSection sectionName="Plankstek" items={plankstek} />
      <MenuSection sectionName="Kall meze" items={kall_meze} />
      <MenuSection sectionName="Varm meze" items={varm_meze} />
      <MenuSection sectionName="Grillspett" items={grillspett} />
      <MenuSection sectionName="Meze Menyer" items={meze_menyer} />
      <MenuSection sectionName="Barn Meny" items={barnnmeny} />
      <MenuSection sectionName="Desserter" items={desserts} />
    </div>
  );
};

export default PriceList;
