import "./lunchInfo.css";
import MenuSection from "./MenuSectionLunch"

const lunch = [
  { name: "WIENERSCHNITZEL", price: "120kr", description: "Serveras med stekt potatis, ärtor, citron och rödvinsås" },
  { name: "FLÄSKNOISETTE", price: "120kr", description: "Serveras med bearnaisesås och pommes" },
  { name: "PANNBIFF", price: "120kr", description: "Med stekt potatis och svampsås" },
  { name: "GRILL TALLRIK", price: "120kr", description: "2 st spett kycklingfilé*, högrevsfärs, serveras med vitlökscreme och pommes." },
  { name: "VÅR FAVORIT", price: "120kr", description: "Var favorit burgare, väster bottenost, bacon, BBQ sås, sallad, tomat och rödlök" },
  { name: "KRÄMIG KYCKLING", price: "120kr", description: "Med svamp och paprika" },
  { name: "CYPARN (Haloumiburgare)", price: "120kr", description: "Friterad halloumiburgare med aioli dressing, sallad, tomat och picklad lök" },
  { name: "CAESARSALLAD Kyckling", price: "120kr", description: "2 st spett kycklingfilé*, högrevsfärs, serveras med vitlökscreme och pommes." },
  { name: "FISH AND CHIPS", price: "120kr", description: "Med citronaioli" },
];

const Lunch = () => {
  return (
    <div className="price-container-lunch">
      <div className="title-container">
        <div className="title-stripe title-stripe-left"></div>
        <div className="title-text">Lunch</div>
        <div className="title-stripe title-stripe-right"></div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <span className="home-text1" style={{ fontSize: "1.3rem" }}>
          Vi serverar lunch, 120:- alla vardagar kl 11-15
        </span>
        <span className="home-text1" style={{ fontSize: "1.3rem" }}>
          Sallad, läsk & kaffe ingår
        </span>
        <span className="home-text1" style={{ fontSize: "1rem", marginTop: "15px" }}>
          (Klicka på menyn för att se info om våra rätter)
        </span>
      </div>
      <MenuSection sectionName="Lunch" items={lunch} />
    </div>
  );
};

export default Lunch;
