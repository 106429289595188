import { useState, useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "./Gallery.css";

type Image = {
  src: string;
  alt: string;
};

const images: Image[] = [
  { src: require("../../assets/images/mat5.webp"), alt: "Bild 5" },
  { src: require("../../assets/images/mat6.webp"), alt: "Bild 6" },
  { src: require("../../assets/images/mat2.jpg"), alt: "Bild 2" },
  { src: require("../../assets/images/mat3.jpg"), alt: "Bild 3" },
  { src: require("../../assets/images/mat4.jpg"), alt: "Bild 4" },
  { src: require("../../assets/images/mat1.jpg"), alt: "Bild 1" },
];

const Gallery = (props: {
  changeCanSwipe: Function;
  hideTitle: boolean;
  showFirstThree: boolean;
}) => {
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);
  const [leftArrowDisabled, setLeftArrowDisabled] = useState(true);
  const [rightArrowDisabled, setRightArrowDisabled] = useState(false);
  const imagesToDisplay = props.showFirstThree
    ? images.slice(0, 3)
    : images.slice(-3);

  useEffect(() => {
    if (selectedImage != null) {
      props.changeCanSwipe(false);
      setLeftArrowDisabled(selectedImage === 1);
      setRightArrowDisabled(selectedImage === images.length);
    }
  }, [selectedImage]);

  const handleSwipeStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setStartX(e.changedTouches[0].clientX);
  };

  const handleSwipeEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    setEndX(e.changedTouches[0].clientX);
    handleSwipe();
  };

  const handleSwipe = () => {
    if (selectedImage && endX - startX > 100) {
      handlePrevious();
    } else if (selectedImage && startX - endX > 100) {
      handleNext();
    }
  };

  const handleNext = () => {
    if (selectedImage && selectedImage < images.length) {
      setSelectedImage(selectedImage + 1);
    } else if (selectedImage === images.length) {
      setRightArrowDisabled(true);
    }
  };

  const handlePrevious = () => {
    if (selectedImage && selectedImage > 1) {
      setSelectedImage(selectedImage - 1);
    } else if (selectedImage === 1) {
      setLeftArrowDisabled(true);
    }
  };

  const handleExit = () => {
    props.changeCanSwipe(true);
    setSelectedImage(null);
  };

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleExit();
    }
  };

  return (
    <div className="gallery-container">
      {props.hideTitle ? null : (
        <div className="title-container">
          <div className="title-stripe title-stripe-left"></div>
          <div className="title-text">Galleri</div>
          <div className="title-stripe title-stripe-right"></div>
        </div>
      )}

      <div className="gallery-images-container">
        {imagesToDisplay.map((image, index) => (
          <img
            src={image.src}
            alt={image.alt}
            key={index}
            className="gallery-image"
            onClick={() => setSelectedImage(index + 1)}
          />
        ))}
      </div>

      {selectedImage && (
        <div
          className="selected-image-overlay"
          onTouchStart={handleSwipeStart}
          onTouchEnd={handleSwipeEnd}
          onClick={handleOutsideClick}
        >
          <img
            src={images[selectedImage - 1].src}
            className="selected-image"
            alt={`Bild ${selectedImage}`}
          />
          {!leftArrowDisabled && (
            <div className="arrow-container arrow-container-left">
              <FaArrowLeft
                style={{ scale: "2", color: "white" }}
                onClick={handlePrevious}
              />
            </div>
          )}
          {!rightArrowDisabled && (
            <div className="arrow-container arrow-container-right">
              <FaArrowRight
                style={{ scale: "2", color: "white" }}
                onClick={handleNext}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Gallery;
