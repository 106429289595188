import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

type Item = {
  name: string;
  description: string;
  price: string;
};

const MenuSection: React.FC<{
  sectionName: string;
  items: Array<{ name: string; price: string; description: string }>;
}> = ({ sectionName, items }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);

  const handleOpenDialog = (item: any) => {
    setSelectedItem(item);
    setOpenDialog(true);
    console.log(item);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="price-list">
      <h4>{sectionName}</h4>
      {items.map(({ name, description, price }) => (
        <div
          key={name}
          className="price-item"
          onClick={() => handleOpenDialog({ name, description, price })}
        >
          <span className="price-item-name">{name}</span>
          <span className="price-item-price">{price}</span>
        </div>
      ))}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {selectedItem?.name} {selectedItem?.price}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{selectedItem?.description}</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MenuSection;
